@font-face {
    font-family: Garet;
    /* src: url(Garet-Book.otf); */
}
#about_body{
    display: flex;
    flex-direction: column;
    font-family: Garet, sans-serif;
}
.about_h1{
    margin-bottom: 50%;
}
.about_section{
    display: flex;
    align-items: center;
    justify-content: center;
}
.about_text_box{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.about_image{
    width: 50%;
}