@font-face {
    font-family: Garet;
    /* src: url(Garet-Book.otf); */
}
#wrapper{
    display: flex;
    align-items: flex-end;
    align-content: flex-end;
    margin: 0px;
    font-family: Garet, sans-serif;
}
#footer_logo {
    object-fit: contain;
    width: 5vw;
    height: 5.5vh;
    margin-top: -1vh;
    margin-left: 1vw;
    margin-right: 0.5vw;
    border: solid white;
}
a{
    text-decoration: none;
}
#footer{
    display: flex;
    flex-direction: column;
    background-color: #155263; /*skyblue; */
    color: white;
    padding: 5vh;
}
#title{
    display: flex;
    margin-left: 8vw;
    margin-bottom: 2vh;
}
#footer_content{
    display: flex;
    justify-content: space-around;
    align-items: center;
    line-height: 200%;
}
.content_section{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
}

hr {
    height: 1px;
    opacity: 30%;
    width: 70%;
    margin: auto;
}