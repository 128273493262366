@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;*/
  font-family: 'Open Sans',sans-serif;
  color: rgb(153, 0, 0);
  /*font-family: 'M PLUS 1p', sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*background-image: url('./bg.png');*/
  /*background: rgb(196,30,58);
  background: radial-gradient(circle, rgba(196,30,58,1) 0%, rgba(255,215,0,1) 96%);*/
  /* background: rgb(255, 199, 44); */
  background-color: white;
  /* background: linear-gradient(0deg, rgba(255, 199, 44) 0%, rgba(253,187,45,1) 100%); */
  background-size: 180% 180%;
  animation-duration: 3s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  animation-name: gradientTransform;
  background-repeat: no-repeat;
  min-width: fit-content
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.invalid-feedback,
.empty-feedback {
  display: none;
}

.was-validated :placeholder-shown:invalid ~ .empty-feedback {
  display: block;
}

.was-validated :not(:placeholder-shown):invalid ~ .invalid-feedback {
  display: block;
}

.is-invalid,
.was-validated :invalid {
  border-color: #dc3545;
}

#root {
  width: 100%
}

