
body {
    text-align: center;
    margin: none;
    color: black;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* font-family: Garet, sans-serif; */
}

#background {
    margin: none;
    min-width: 100%;
    min-height: 100%;
}

#bg_vid {
    min-width: 100%;
    min-height: 100%;
}

#nav_bar {
    /* position: fixed; */
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 1vw;
    margin-top: 1vh;
    width: 100%;
    background-color: white;
    color: black;
}

#logo {
    object-fit: contain;
    width: 5vw;
    height: 5.5vh;
    /* margin-top: -1vh; */
    margin-left: 1vw;
    margin-right: 0.5vw;
}

#logo_title {
    font-size: 25px;
    margin: 1vh;
    margin-right: 1.5vw;
    margin-top: 0.8vh;
}

.vertical_line {
    border-left: 3px solid black;
    height: 4.5vh;
    margin: 1vh;
    margin-top: 0.5vh;
    display: flex;
}

#nav_vert {
    border-left: 3px solid #A9DEF9;
}

#nav_form {
    /* display: flex; */
    justify-content: space-around;
    flex-grow: 2;
}

.text_button {
    border: none;
    background-color: inherit;
    cursor: pointer;
    padding: 1vh 1.5vh;
    font-size: 16px;
    height: 5vh;
    margin: 0.25vh;
    margin-right: 4vh;
    border-radius: 7px;
    float: right;
    display: inline;
    color: black;
    /* font-family: Garet, sans-serif; */
}

    .text_button:hover {
        background-color: #eee;
        color: black;
    }

#search_section {
    background-color: rgb(238, 238, 238);
    border-radius: 7px;
    height: 5vh;
    margin: 0.75vh;
    display: flex;
    flex-direction: row;
    flex-grow: 2;
    margin-top: 0.2vh;
}

#search_bar {
    border-radius: 7px;
    border: none;
    height: 5vh;
    padding: 0.5vh;
    background: transparent;
    flex-grow: 2;
    font-size: 18px;
}

input#search_bar:focus {
    outline-width: 0;
}

.img_button {
    color: #A9DEF9;
    font-size: 3vh;
    margin: 1.5vh;
    margin-top: 1.5vh;
    cursor: pointer;
}

#magnifying_glass {
    font-size: 2vh;
    margin: 1.5vh;
}

#spotlight {
    text-align: left;
    color: white;
}

.slideshows {
    margin: auto;
    width: 90vw;
    height: 50vh;
    overflow: visible;
}

img {
    width: 90vw;
    height: 50vh;
    object-fit: cover;
    border-radius: 10px;
}

.spot_title {
    position: relative;
    bottom: 28vh;
    left: 5vw;
    font-size: 5vh;
    padding: 5px;
}

.details {
    position: relative;
    bottom: 28vh;
    left: 5vw;
    font-size: 2.5vh;
    line-height: 1.25vh;
    padding: 7px 0px;
}

#categories {
    display: flex;
    justify-content: space-between;
    margin: 1vh;
    margin-top: 2.5vh;
    width: 92%;
    position: relative;
}

.big_font {
    font-size: 1.75vw;
    font-weight: bold;
    margin: 0.3vw;
    /* margin-left: 0.3vw;
    margin-right: 0.3vw; */
    padding-right: 0.5vw;
    padding-left: 0.5vw;
    line-height: 5vh;
}

.sort {
    background: transparent;
}

#default_sort {
    border-bottom: 5px solid #E4C1F9;
}

#category_form {
    display: flex;
}

.category {
    font-size: 20px;
    background: transparent;
}

#cat_vert {
    border-left: 3px solid #E4C1F9;
}

#default_all {
    background-color: #E4C1F9;
}

#events {
    display: flex;
    width: 90%;
    justify-content: center;
    flex-direction: column;
    margin-top: 1.5vh;
}

table, th, td {
    border: 3px solid black;
}

#event_table {
    display: inline-flex;
}

#headers {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.header {
    width: fit-content;
    display: flex;
    flex-direction: column;
    margin-left: 0.5vw;
    margin-right: 0.5vw;
}

.table_column {
    font-size: 1vw;
    display: flex;
    color: lightslategray;
    width: fit-content;
    justify-content: flex-end;
    margin-bottom: 1vh;
}

#artist {
    margin-left: 0vh;
    text-align: left;
}

#tour {
    justify-content: flex-end;
}

#venue {
    margin-right: 0.5vw;
    justify-content: flex-end;
}

.section_info {
    font-size: 1.4vw;
    height: 6vh;
    width: fit-content;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
}

.thumbnail {
    width: 3vw;
    height: 5vh;
    margin: 0.25vw;
    padding: 0.25vw;
    object-fit: cover;
    display: inline-block;
    line-height: 5vh;
}

.list {
    font-size: 1.2vw;
    color: #d49ff3;
}

#search_field {
    width: 90%;
    margin: auto;
}

@media screen and (max-width: 1000px) {
    #nav_bar {
        width: 85%;
    }

    #logo_title {
        font-size: 26px;
    }

    .category {
        font-size: 16px;
    }

    #cat_vert {
        margin: 1vh;
    }
}

@media screen and (max-width: 900px) {
    #nav_bar {
        width: 85%;
    }

    #logo_title {
        font-size: 24px;
    }

    .category {
        font-size: 15px;
        height: 5vh;
        margin-top: 1vh;
    }

    #cat_vert {
        margin: 1.25vh;
    }
}

@media screen and (max-width: 800px) {
    #logo_title {
        font-size: 18px;
    }

    #logo {
        width: 5vw;
        height: 5.5vh;
    }

    .category {
        font-size: 13px;
        height: 4vh;
        margin-top: 1.25vh;
    }

    .sort {
        height: 3.5vh;
    }

    #cat_vert {
        margin: 1.5vh;
    }
}

@media screen and (max-width: 700px) {
    #logo_title {
        font-size: 16px;
    }

    .category {
        font-size: 11px;
    }

    .sort {
        height: 3.25vh;
    }

    #cat_vert {
        margin: 1.75vh;
    }
}

@media screen and (max-width: 600px) {
    #nav_bar {
        width: 75%;
    }

    #logo_title {
        font-size: 12px;
    }

    #logo {
        width: 7vw;
        height: 5.5vh;
    }

    .category {
        font-size: 8px;
        height: 4vh;
        margin-top: 1.5vh;
    }

    .sort {
        height: 2.85vh;
    }

    #cat_vert {
        margin: 1.9vh;
    }
}

@media screen and (max-width: 500px) {
    #logo_title {
        font-size: 14px;
    }

    #logo {
        width: 9vw;
        height: 5.5vh;
    }

    .category {
        font-size: 7px;
        height: 3vh;
        margin-top: 1.75vh;
    }

    .sort {
        height: 2.5vh;
    }

    #cat_vert {
        margin: 2.1vh;
    }
}
